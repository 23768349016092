import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

/**
 *
 */
class NotFoundPage extends React.Component {
    /**
     *
     * @returns {XML}
     */
    render() {
        return (
            <Layout>
                <SEO title="404: Not found"/>

                <div className="container mt-3">
                    <h1 className="text-center">Page Not Found</h1>

                    <p>Bummer, the page you are looking for can not be found at this time.</p>

                    <div className="text-center mt-5">
                        <Link className="btn btn-secondary" to="/">Back Home</Link>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default NotFoundPage
